import React, { useEffect } from 'react';
import authStore from '../stores/authStore';

export default function LogoutPage() {
    const store = authStore.getState();

    useEffect(()=>{
        store.logout();
    });
  return (
    <div><h1>
        You are Now logged Out!</h1></div>
  )
}

import { Link } from 'react-router-dom';

export default function NavLinks() {
  return (
    <div className='sideNav' >
        <ul className="navList">
          <li className='linkItem'>
            <Link className="link" to='/'>Home </Link>
          </li>
          <li className='linkItem'>
              <Link className="link" to='/signup'>Add User </Link>
          </li>
          <li className='linkItem'>
              <Link className="link" to='/devices'>Add Device </Link>
          </li>
          <li className="linkItem">
                  <Link className="link" to='/login'>Login </Link>
          </li>
          <li className="linkItem">
                <Link className="link" to='/logout'>Logout </Link>
          </li>
        </ul>
    </div>
  )
}

import {BrowserRouter, Routes, Route, Link} from 'react-router-dom';
import RequireAuth from './RequireAuth';
import DevicePage from '../pages/DevicePage';
import LoginPage from '../pages/LoginPage';
import SignupPage from '../pages/SignupPage';
import LogoutPage from '../pages/LogoutPage';
import CreateForm from './CreateForm';
import NavLinks from './NavLinks';



function App() {

  return (
    <div className="App">
      
      <BrowserRouter>
        <div className='appBar' >
          <div className="appTitle"> 
            <h1> Flyght Intake System </h1>
          </div>
          <div><NavLinks /></div>
        </div>

        <Routes>

          <Route index element={
            <RequireAuth>
              <DevicePage/>
            </RequireAuth> 
            }/>

          <Route path='/devices' element={
            <CreateForm/>
          }/>

          

          <Route path='/signup' element={
            <RequireAuth>
              <SignupPage />
            </RequireAuth>
          }/>

          <Route path='/login' element={
            <LoginPage/>
          }/>

          <Route path='/logout' element={
            <LogoutPage />
          }/>
            
        </Routes>
      </BrowserRouter>
    </div>  
  );
}

export default App;


import '../App.css';
import {  useEffect } from 'react';
import deviceStore from '../stores/deviceStore';
import DeviceList from '../components/DeviceList';
import NavLinks from '../components/NavLinks';
import CreateForm from '../components/CreateForm';
import UpdateForm from '../components/UpdateForm';



export default function DevicePage() {
  const store = deviceStore();

  //Use Effect
  useEffect(()=>{
    store.fetchDevices();
  });



  return (
    <div className="mainView">
        <div name='viewDevice'>
          <div className='listView'>
            <DeviceList />
          </div>
          <hr/>
          <div className='formsView'>
            <CreateForm />
            <UpdateForm />
          </div>
        </div>
        
    </div>
  )
}



import authStore from "../stores/authStore";
import { useNavigate } from "react-router-dom";


export default function LoginForm() {
    const store = authStore();
    const navigate = useNavigate();

    const handleLogin = async (e)=>{

        e.preventDefault();

        await store.login();

        //Navigate
        navigate('/');
    };

  return (
    <form className='loginForm' onSubmit={handleLogin}>
       <div className='formFields'>
            <div className='inputBlock'>
                <label className='inputLabels '>Email:</label>
                <input type='email' name='email' className='inputFields ' value={store.loginForm.email} onChange={store.updateLoginForm}/>
            </div>

            <div className='inputBlock'>
                <label className='inputLabels ' >Password:</label>
                <input type='password' name='password'  className='inputFields' value={store.loginForm.password} onChange={store.updateLoginForm}/>
            </div>  

            <div className='formBtn'>
                <button type='submit' className='submitBtn'>Login</button>
            </div>
       </div>
       
    </form>
  )
}

import authStore from '../stores/authStore';
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function RequireAuth(props) {
    const store = authStore();

  useEffect(()=>{
    if(store.loggedIn === null){
      store.checkAuth();
    }
  });

 if(store.loggedIn === null ){
  return <div>
          <h1>
            <i>Intake System Starting...Please Wait</i>
          </h1>
        </div>;
 }

 if(store.loggedIn === false ){
  return <div>
          <Navigate to='/login'/>;
        </div>;
 }

  return (
    <div>{props.children}</div>
  )
}


import { create } from 'zustand';
import axios from 'axios';

const deviceStore = create((set)=>({
    devices: null,

    createForm: {
        intakeDate: '',
        intakeBy: '',
        deviceType: '',
        deviceSerial: '',
        deviceMAC: '',
        redeployStatus: '',
        intakeNotes: '',
        deviceModel:'',
        deviceMFG:'',

    },

    updateForm: {
        _id: null,
        intakeDate: '',
        intakeBy: '',
        deviceType: '',
        deviceSerial: '',
        deviceMAC: '',
        redeployStatus: '',
        intakeNotes: '',
        deviceModel:'',
        deviceMFG:'',
    },


    fetchDevices: async ()=>{
           
            //Fetch devices
            const res = await axios.get('/devices');
            //Set to State
            set({ devices: res.data.devices });
    },

    updateCreateFormField: (e)=>{
            const {name, value} = e.target;

        set((state)=>{
            return {
                createForm:{
                    ...state.createForm,
                    [name] : value,
                }
            }
        })
    },

    createDevice: async(e) =>{
        e.preventDefault();

      
            const { createForm, devices } = deviceStore.getState();
            const res = await axios.post('/devices', createForm);
        

            set({
                devices: [...devices, res.data.device],
                createForm: {
                    intakeDate: '',
                    intakeBy: '',
                    deviceType: '',
                    deviceSerial: '',
                    deviceMAC: '',
                    redeployStatus: '',
                    intakeNotes: '',
                    deviceModel:'',
                    deviceMFG:'',
                }
            });
            console.log(res);
       
    },

    deleteDevice: async(_id) =>{
        //Delete the note
        const res = await axios.delete(`/devices/${_id}`);
        const { devices } = deviceStore.getState();
             
        //Update the State
        const newDevices = devices.filter(device =>{
          return device._id !== _id;
        })
      
        set({ devices: newDevices });
       
    },

    handleUpdateFieldChange:  (e) =>{
        const {value, name} = e.target;
      
        set((state) => {
            return {
                updateForm: {
                    ...state.updateForm,
                    [name]: value,
                },
            };
        });
    },

    toggleUpdate: ({ 
         _id,
         intakeDate,
         intakeBy,
         deviceType,
         deviceSerial, 
         deviceMAC,
         redeployStatus,
         intakeNotes,
         deviceModel,
         deviceMFG, }) =>{
       
        set({
            updateForm: {
                _id,
                intakeDate,
                intakeBy,
                deviceType,
                deviceSerial,
                deviceMAC,
                redeployStatus,
                intakeNotes,
                deviceModel,
                deviceMFG,
            },
        });
    },

    updateDevice: async(e) =>{
        e.preventDefault();
        const { 
            updateForm: { _id, intakeDate, intakeBy, deviceType, deviceSerial, deviceMAC, redeployStatus, intakeNotes, deviceModel,
                deviceMFG }, devices
         } = deviceStore.getState();


        //Send the updated info
        const res = await axios.put(`/devices/${_id}`, {
          intakeDate,
          intakeBy,
          deviceType,
          deviceSerial,
          deviceMAC,
          redeployStatus,
          intakeNotes,
          deviceModel,
          deviceMFG,
        });
      
        const newDevices = [...devices];
        const deviceIndex = devices.findIndex(device =>{
          return device._id === _id;
        });
      
        newDevices[deviceIndex] = res.data.device;

        set({
            devices: newDevices,
            updateForm: {
                _id: null,
                intakeDate: '',
                intakeBy: '',
                deviceType: '',
                deviceSerial: '',
                deviceMAC: '',
                redeployStatus: '',
                intakeNotes: '',
                deviceModel:'',
                deviceMFG:'',
            },
        });
    },

}));

export default deviceStore;
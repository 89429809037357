import React from 'react';
import { useNavigate } from 'react-router-dom';
import authStore from '../stores/authStore';


export default function SignupForm() {

    const store = authStore();

    const navigate = useNavigate();

    const handleSignup = async (e)=>{

        e.preventDefault();

        await store.signup();

        // Navigate
        navigate('/');
    };

  return (
    <form className='signupForm' onSubmit={handleSignup}>
       <div className='formFields'>
            <div className='inputBlock'>
                <label className='inputLabels' >Email:</label>
                <input className='inputFields' type='email' name='email' value={store.signupForm.email} onChange={store.updateSignupForm}></input>
            </div>
         
            <div className='inputBlock'>
                <label className='inputLabels'>Password:</label>
                <input className='inputFields' type='password' name='password' value={store.signupForm.password} onChange={store.updateSignupForm}></input>
            </div> 

            <div className='formBtn'>
                <button type='submit' className='submitBtn'>Create User</button>
            </div> 
       </div>
    </form>
  )
}

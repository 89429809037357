import deviceStore from "../stores/deviceStore";
import Device from "./Device";
import NavLinks from "../components/NavLinks";


export default function Devices() {
    const store = deviceStore();
    return(
        <div className='deviceView'>
            
            <div className='tableTitle'>Devices:</div>
           
            <table className="devicesTable">
                        <thead className='tblHead'>
                            <th>Date</th>
                            <th>By</th>
                            <th>Type</th>
                            <th>Model</th>
                            <th>MFG</th>
                            <th>Serial</th>
                            <th>MAC</th>
                            <th>Redeploy</th>
                            <th>Notes</th>
                            <th>Actions</th>
                        </thead>
                        
                        <tbody>
                        {store.devices && store.devices.map(device =>{
                        return(
                            <tr className='deviceRow' key={device._id}>
                                <td>{device.intakeDate}</td>
                                <td>{device.intakeBy}</td>
                                <td>{device.deviceType}</td>
                                <td>{device.deviceModel}</td>
                                <td>{device.deviceMFG}</td>
                                <td>{device.deviceSerial}</td>
                                <td>{device.deviceMAC}</td>
                                <td>{device.redeployStatus}</td>
                                <td>{device.intakeNotes}</td>
                                <td className='actBtns'>
                                    <button className='actBtn' onClick={()=> store.toggleUpdate(device)}>Edit</button>
                                    <button className='actBtn' onClick={()=> store.deleteDevice(device._id)}>Delete</button>
                                </td>
                            </tr>)})}
                        </tbody>
                    </table> 
            </div>
        );
}

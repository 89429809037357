import React from 'react';
import SignupForm from '../components/SignupForm';


export default function SignupPage() {
  return (
    <div>
        <div className="pageTitle">
          <h2>Create User</h2>
        </div>
        
        <div>
          <SignupForm/>
        </div>
        
    </div>
  )
}

import LoginForm from "../components/LoginForm";


export default function LoginPage() {
  return (
    <div>
        <div className="pageTitle">
          <h2>Login</h2>
        </div>
        
        <div>
          <LoginForm/>
        </div>
        
    </div>
  )
}

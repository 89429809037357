import deviceStore from "../stores/deviceStore"
import NavLinks from "./NavLinks";


export default function CreateForm() {

  const store = deviceStore();

  if (store.updateForm._id) return <></>
  
  return (
    
    <div class='formGrid'>

      <div className='formView'>
        <div className="formTitle">Intake Form</div>

        <form className='intakeForm' onSubmit={store.createDevice}>
          <div className='formStart'>
            <div className='formRow'>
                <label name="intakeDate">Date:</label>
                <input name="intakeDate" type="date" value={store.createForm.intakeDate} onChange={store.updateCreateFormField} ></input>
              </div>

              <div className='formRow'>
                <label name="intakeBy">Employee:</label>
                <input name="intakeBy" type='text' value={store.createForm.intakeBy} onChange={store.updateCreateFormField} ></input>
              </div>
          </div>
          <div className='formMid'>
            <div className='formRow'>
              <label name="deviceType">Type:</label>  
              <select name="deviceType" type='text' value={store.createForm.deviceType} onChange={store.updateCreateFormField}>
                <option value="">Select a device type</option>
              <optgroup label="Voice" >
                  <option value="Wired Phone">Wired Phone</option>
                  <option value="Wireless Handset">Wireless Handset</option>
                  <option value="Wireless Base">Wireless Base</option>
                  <option value="BlueTooth Headset">BlueTooth Headset</option>
                </optgroup>
                <optgroup label="Protect">
                  <option value="Switch24">24 Port Switch</option>
                  <option value="Switch48">48 Port Switch</option>
                  <option value="Firewall">Firewall</option>
                </optgroup>
                <optgroup label="POS/DW">
                  <option value="Terminal">Terminal</option>
                  <option value="PayDevice">Payment Device</option>
                  <option value="Impact">Impact Printer</option>
                  <option value="Thermal">Thermal Printer</option>
                </optgroup>
                <optgroup label="View">
                  <option value="Camera">Camera</option>
                  <option value="NVR">NVR/DVR</option>
                </optgroup>
                <optgroup label="WIFI">
                  <option value="Access Point">Access Point</option>
                </optgroup>
              </select>
            </div>

            <div className='formRow'>
              <label name="deviceModel">Model:</label>  
              <input name="deviceModel" type='text' value={store.createForm.deviceModel} onChange={store.updateCreateFormField} ></input>
            </div>

            <div className='formRow'>
              <label name="deviceMFG">MFG:</label>  
              <select name="deviceMFG" type='text' value={store.createForm.deviceMFG} onChange={store.updateCreateFormField}>
                <option value="">Select a Manufacturer</option>
                <option value="Epson">Epson</option>
                <option value="Datto">Datto</option>
                <option value="Rabbit Run">Rabbit Run</option>
                <option value="YeaLink">YeaLink</option>
                <option value="GrandStream">Grand Stream</option>
                <option value="Apple">Apple</option>
                <option value="Pax">Pax</option>
                <option value="Magensa">Magensa</option>
              </select>
            </div>

            <div className='formRow'>
              <label name="deviceSerial">Serial:</label>  
              <input name="deviceSerial" type='text' value={store.createForm.deviceSerial} onChange={store.updateCreateFormField} ></input>
            </div>

            <div className='formRow'>
              <label name="deviceMAC">MAC:</label>  
              <input name="deviceMAC" type='text' value={store.createForm.deviceMAC} onChange={store.updateCreateFormField}></input>
            </div>

            <div className='formBtn'>
                <button type="submit" className='submitBtn'>Add Device</button>
            </div> 
          </div>
          <div className="formEnd">
            <div className='formRow'>
              <label name="redeployStatus">Redeploy:</label> 
              <select name="redeployStatus" type='text' value={store.createForm.redeployStatus} onChange={store.updateCreateFormField}>
                <option value=''>Select a deployment status</option>
                <option value='OK'>OK</option>
                <option value='RMA'>RMA</option>
              </select>
            </div>
            <div className='formRow'>
              <label name="intakeNotes">Notes:</label> 
              <textarea name="intakeNotes" type='text' className="notesArea"value={store.createForm.intakeNotes} onChange={store.updateCreateFormField}></textarea>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
